import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, useParams, useLocation } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalState';
import Loader from './gif/loader.svg'
import Cookies from './components/cookies/cookies'
import gtmInit from './utils/gtm.js';
import trackEvent from './utils/trackEvent.js';
const Home = lazy(() => import('./components/pages/Home'));
const ThankYou = lazy(() => import('./components/pages/ThankYou'));
const SmileBuilder = lazy(() => import('./components/pages/SmileBuilder'));


function App() {
  useEffect(() => {
    gtmInit('GTM-WBGF8WB');  // Replace GTM-XXXXXX with your GTM ID
    // trackEvent('Page Visit', 'Home Page Access', 'Visited the Home page');
  }, []);

  return (
    <GlobalProvider >
      <Router>
        <Suspense fallback={<div style={
          {
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          }
        }>
          <img src={Loader} alt=''></img></div>}>
          <Switch>

            <Route exact path="/" component={Home} />
            <Route exact path="/thank-you" component={ThankYou} />
            {/* <Route exact path="/smilebuilder" component={SmileBuilder} /> */}

          </Switch>
          <Cookies />
        </Suspense>
      </Router>
    </GlobalProvider>
  );
}

export default App;
