import React, {useState,useEffect, useContext} from 'react';
import { GlobalContext } from '../../context/GlobalState';
import Privacy from '../privacy/privacy.js'
import './cookies.css'
import './button.css'
import Button from '../button/Button'

const Cookies = (props) => {
    const { show } = useContext(GlobalContext);
    const { showBanner } = useContext(GlobalContext)
    const { closePrivacy } = useContext(GlobalContext)
  
  
    return (
      <>
        <Privacy />
        {show &&
  
          <div className="cookies show-cookie-container" >
            <div className="">
              <div className="row">
                <div className="col-x-lg-9 col-md-7 col-12">
                  <h1>Privacy</h1>
                  <p>To provide you with the best possible user experience, this website uses cookies and similar technologies to help personalise content and tailor ads. By clicking OK, or allowing cookies by managing your preferences, you agree to this as outlined in our Privacy Policy.</p>
                </div>
                <div className="col-x-lg-3 col-md-5 col-12 text-center cookies--button_container">
                  <button className="mybtn mybtn-primary-transparent" onClick={event => {closePrivacy(1)}}>Cookie Settings</button>
                  <button  className="mybtn mybtn-primary" onClick={event => {showBanner(0)}}>OK</button>
                </div>
              </div>
            </div>
          </div> }
      </>
    );
  };
  
  export default Cookies;