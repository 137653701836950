function trackEvent(eventCategory, eventAction, eventLabel = '', eventValue = null) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'interaction', // This event name must match the trigger in GTM
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
    });
}

export default trackEvent;