import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

// Initial state
const initialState = {
    show: true,
    toggleState: true,
    showPrivacyModal: false,
    showModal: false,
    assessmentForm: {
      averagePrice : "no assessment taken",
      weeks: 0
    },
}

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function showBanner(show) {
    dispatch({
      type: 'UPDATE_SHOW',
      payload: show
    });
  }
  function closePrivacy(show) {
    dispatch({
      type: 'CLOSE_PRIVACY',
      payload: show
    });
  }
  function setToggleState(show) {
    dispatch({
      type: 'TOGGLE',
      payload: show
    });
  }
  function setAssessmentData(data){
    dispatch({
      type: 'ASSESSMENT',
      payload: data
    })
  }

  return (<GlobalContext.Provider value={{
    show: state.show,
    toggleState: state.toggleState,
    showPrivacyModal: state.showPrivacyModal,
    showModal: state.showModal,
    assessmentForm: state.assessmentForm,
    showBanner,
    closePrivacy,
    setToggleState,
    setAssessmentData,
  }}>
    {children}
  </GlobalContext.Provider>);
}