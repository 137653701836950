export default (state, action) => {
    switch (action.type) {
        case 'UPDATE_SHOW':

            return {
                ...state,
                show: action.payload === 1 ? true : false
            }
        case 'CLOSE_PRIVACY':
            return {
                ...state,
                showPrivacyModal: action.payload === 1 ? true : false
            }
        case 'TOGGLE':
            return {
                ...state,
                toggleState: action.payload
            }
        case 'ASSESSMENT':
            return {
                ...state,
                assessmentForm: action.payload
            }
        default:
            return state;
    }
}