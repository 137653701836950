import React, { Component } from "react";
import './button.css'
import Arrow from '../../img/arrow_left.png'
import ArrowDark from '../../img/arrow_left_dark.png'
import Back from '../../img/return.svg'
import { Link } from "react-router-dom";



export default class Button extends Component {

    render(){
        return(
                <Link to={this.props.href} 
                onClick={this.props.onClick} 
                target={this.props.target}
                className={'mybtn '+this.props.className}>
                    {this.props.back && <img alt='' src={Back}/>}
                    {'  '}{this.props.title}{'  '}
                    {this.props.icon && <img alt='' src={this.props.icon === 'dark' ? ArrowDark : Arrow}/>}
                </Link>
        )
    }
}